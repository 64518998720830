<template>
<div>
    <v-overlay absolute :opacity=".6" :color="TemaDark?'grey darken-4':'white'" :value="overlay" z-index="2">
        <span :class="TemaDark?'white--text':'black--text'">Cargando...</span>
        <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
    </v-overlay>
    <div v-if="skeleton">
        <v-skeleton-loader type="date-picker" />
    </div>
    <v-card flat class="rounded-lg">
        <v-toolbar flat class="rounded-t-lg" v-if="!skeleton" height="56">
            <v-menu ref="menu" v-model="menu_mes" :close-on-content-click="false" bottom right origin="top left" transition="scroll-x-transition" rounded="lg">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mr-2 ml-0 toolbar-menu-btn" :class="TemaDark?'primary':'secondary'" v-bind="attrs" v-on="on">
                        <v-icon :color="TemaDark?'white':'primary'" size="20" class="icon_btn_picker">mdi-calendar</v-icon>
                    </v-btn>
                </template>
                <v-card  class="rounded-lg" flat>
                    <v-date-picker v-model="boton_mes" type="month" scrollable flat :show-current="true" no-title @change="$refs.menu.save(GetMes(boton_mes, 0))" width="280" :min="calendario.inicio" :max="calendario.termino">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.menu.save(GetMes(FechaHoy, 0))">Hoy</v-btn>
                        <v-btn text color="primary" @click="menu_mes = false">Cerrar</v-btn>
                    </v-date-picker>
                </v-card>
            </v-menu>
            <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                <v-list-item class="px-0">
                    <v-list-item-content class="py-0">
                        <v-list-item-title class="headline mb-0">
                            <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" style="line-height: 1.1;" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{CalendarioTitulo.mes}}</v-toolbar-title>
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-weight-medium mb-0">
                            <span class="text-capitalize">{{CalendarioTitulo.año}}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="edit" class="pr-1">
                <v-btn icon color="primary" @click="edit_mode = !edit_mode">
                    <v-icon size="20">mdi-calendar-edit</v-icon>
                </v-btn>
            </div>
            <div v-if="CalendarioCercano.anterior" class="pr-1">
                <v-btn icon color="primary" @click="GetMes(calendario.mes,-1)">
                    <v-icon size="20">mdi-chevron-left</v-icon>
                </v-btn>
            </div>
            <div v-else>
                <v-btn icon disabled>
                    <v-icon size="20">mdi-close</v-icon>
                </v-btn>
            </div>
            <div v-if="CalendarioCercano.siguiente">
                <v-btn icon color="primary" @click="GetMes(calendario.mes,1)">
                    <v-icon size="20">mdi-chevron-right</v-icon>
                </v-btn>
            </div>
            <div v-else>
                <v-btn icon disabled>
                    <v-icon size="20">mdi-close</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
    </v-card>
    <v-alert dense color="primary" dark class="mx-7 mt-2" transition="fade-transition" :value="edit_mode" icon="mdi-information-outline"> Modo edición </v-alert>
    <v-simple-table class="rounded-b-lg px-2" id="calendario" v-if="!skeleton">
        <thead v-if="calendario.semanas">
            <tr>
                <th class="text-center">L</th>
                <th class="text-center">M</th>
                <th class="text-center">X</th>
                <th class="text-center">J</th>
                <th class="text-center">V</th>
                <th class="text-center">S</th>
                <th class="text-center">D</th>
            </tr>
        </thead>
        <tbody v-if="calendario.semanas">
            <tr v-for="(semana, index) in calendario.semanas" :key="index">
                <td v-for="(dias, index) in semana" :key="index" class="text-center px-0" style="width: 60px;" @click="!edit_mode?GetTurno(dias.fecha):null" :class="Hoy(dias.fecha)?'':''">
                    <div v-ripple="dias.turnos? { class: `grey--text` }: null" style="height:52px" :class="dias.turnos? Hoy(dias.fecha)?'celda-hoy':null:'celda-data'" class="rounded">
                        <div v-if="dias.turnos">
                            <span class="text-caption text-uppercase" :class="Hoy(dias.fecha)?'celda-hoy':FechaFeriada(dias.fecha)? 'red--text':'grey--text'">{{dias.fecha.slice(8)}}</span>
                        </div>
                        <div v-if="dias.turnos">
                            <p class="ma-0 nombre-turno font-weight-medium text-uppercase" :class=" Hoy(dias.fecha)?'celda-hoy':FechaFeriada(dias.fecha)? 'red--text':TemaDark?'grey--text text--lighten-2':'grey--text text--darken-1'" v-for="(turno, index) in dias.turnos" :key="index">
                                <span :class="(turno.extra || turno.doble)? 'text-decoration-underline': null">
                                    {{turno.nombre}}
                                </span>
                            </p>
                        </div>
                        <div v-else>
                            <p class="ma-0 grey--text">-</p>
                        </div>
                    </div>
                </td>
            </tr>
            <tr v-if="StatusDialog == false || vista_resumen " @click="GetDetalleServicios()">
                <td colspan="7" class="px-0 pb-2">
                    <v-card flat v-ripple="{ class: `grey--text` }" height="50" class="mx-auto d-flex justify-center mt-2 pt-1 pb-2">
                        <v-row class="ma-0" v-if="StatusDialog == false">
                            <v-col class="py-0 px-1" cols="6">
                                <v-card flat class="text-center">
                                    <v-avatar color="primary" size="28">
                                        <span class="white--text">{{calendario.servicios}}</span>
                                    </v-avatar>
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-2'">Servicios</v-list-item-title>
                                </v-card>
                            </v-col>
                            <v-divider vertical class="calendario_divider mb-2"/>
                            <v-col class="pa-0 px-1" cols="6">
                                <v-card flat class="text-center">
                                    <v-avatar size="28" :color="(calendario.fu > 0)? 'red':'grey'">
                                        <span class="white--text">{{calendario.fu}}</span>
                                    </v-avatar>
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-2'">F.U.</v-list-item-title>
                                </v-card>
                            </v-col>
                            <!--<v-divider vertical class="calendario_divider mb-2"/>
                            <v-col class="pa-0 px-1" cols="4">
                                <v-card flat class="text-center">
                                    <v-avatar size="28" color="primary">
                                        <span class="white--text">--</span>
                                    </v-avatar>
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-2'">Horas</v-list-item-title>
                                </v-card>
                            </v-col>-->
                        </v-row>
                        <v-btn text color="primary" v-else @click="GetDetalleServicios()"> Ver registro </v-btn>
                    </v-card>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="7" class="text-center" style="height:300px">
                    <v-card flat>
                        <v-icon size="80" color="grey lighten-1">
                            mdi-calendar-remove-outline
                        </v-icon>
                    </v-card>
                    <span class="trenos-title-toolbar grey--text text--lighten-1">Sin turnos asignados</span>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
    <v-dialog v-model="dialog_turno" persistent max-width="450" :transition="DialogAnimation" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-fade-transition>
            <v-card class="rounded-lg" flat v-show="!DialogServicio">
                <Turno :fecha="turno_fecha" :in_dialog="true" :only_read="OnlyRead" :usuario_id="usuario_id" :dialog_btn="true" :vista="'calendario'" />
            </v-card>
        </v-fade-transition>
    </v-dialog>   
    <CalendarioResumen :datos="resumen"/>
</div>
</template>

<script>
import dayjs from "dayjs"
import {
    mapState,
    mapMutations
} from 'vuex';
export default {
    components: {
        Turno: () => import('@/components/Turno'),
        CalendarioResumen : () => import('@/components/CalendarioResumen')
    },
    name: "Calendario",
    props: ['usuario_id', 'fecha'],
    data() {
        return {
            edit: false,
            calendario: {
                mes: null,
                semanas: [],
                feriados: [],
                servicios: null,
                rango: {
                    ini: null,
                    termino: null
                },
                fu: null
            },
            boton_mes: null,
            menu_mes: false,
            overlay: false,
            turno_fecha: null,
            skeleton: true,
            resumen: {},
            dialog_turno: false,
            vista_resumen: false,
            edit_mode: false
        }
    },
    computed: {
        ...mapState(['usuario', 'dialog', 'update','sistema']),
        StatusDialog() {
            let status = false
            if (this.dialog.usuario) status = true
            return status
        },
        OnlyRead() {
            if (this.usuario_id) return true
            else return false
        },
        UpdateComponente() {
            return this.update.calendario
        },
        CalendarioCercano() {
            let cercano = {
                anterior: false,
                siguiente: false
            }
            if (this.calendario.inicio < dayjs(this.calendario.mes).format('YYYY-MM-DD')) {
                cercano.anterior = true
            }
            if (this.calendario.termino > dayjs(this.calendario.mes).endOf('month').format('YYYY-MM-DD')) {
                cercano.siguiente = true
            }
            return cercano
        },
        CalendarioTitulo() {
            let calendario = {
                mes: dayjs(this.calendario.mes).format('MMMM'),
                año: dayjs(this.calendario.mes).format('YYYY')
            }
            return calendario
        },
        FechaHoy() {
            return dayjs().format('YYYY-MM-DD')
        },
        DialogTurno() {
            return this.dialog.turno
        },
        //Entrega el estado del dialog servicio (si esta abierto o no).
        DialogServicio(){
            let hide_dialog = false
            if(this.dialog.servicio) hide_dialog = true
            return hide_dialog
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods: {
        ...mapMutations(['ShowSnackBar', 'LogOut', 'ErrorRequest', 'CloseDialog', 'OpenDialog']),
        Hoy(fecha) {
            if (fecha == dayjs().format('YYYY-MM-DD')) {
                return true
            } else {
                return false
            }
        },
        FechaFeriada(fecha) {
            if ((this.calendario.feriados.find(element => element == dayjs(fecha).format('YYYY-MM-DD'))) || dayjs(fecha).isoWeekday() == 7) {
                return true
            } else {
                return false
            }
        },
        async GetData(fecha, usuario_id) {
            this.overlay = true
            fecha = dayjs(fecha).format('YYYYMM')
            let url = `/calendario/${fecha}`
            if (usuario_id) url = `/calendario/${fecha}/${usuario_id}`
            await this.axios.get(url, { headers: { token: this.usuario.token}
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.overlay = false
                    this.skeleton = false
                    if(respuesta.data.edit) this.edit = true
                    if(respuesta.data.resumen) this.vista_resumen = true
                    else this.vista_resumen = false
                    if (respuesta.data.calendario) {
                        let dias_largo = respuesta.data.calendario.length,
                            semanas = [],
                            semana = [],
                            dia_primer = dayjs(respuesta.data.calendario[0].fecha).format('YYYY-MM-DD'),
                            dia_ultimo = dayjs(respuesta.data.calendario[dias_largo - 1].fecha).format('YYYY-MM-DD')
                        for (let index = 0; index < dias_largo; index++) {
                            if (respuesta.data.calendario[index].fecha == dia_primer) {
                                let dia_primer_num = dayjs(respuesta.data.calendario[0].fecha).isoWeekday()
                                for (let index = 1; index < dia_primer_num; index++) {
                                    semana.push({
                                        fecha: 0,
                                        turno: 0
                                    })
                                }
                            }
                            if (dayjs(respuesta.data.calendario[index].fecha).isoWeekday() == 1) {
                                semanas.push(semana)
                                semana = []
                                semana.push(respuesta.data.calendario[index])
                            } else {
                                semana.push(respuesta.data.calendario[index])
                            }
                            if (respuesta.data.calendario[index].fecha == dia_ultimo) {
                                let dia_ultimo_num = dayjs(respuesta.data.calendario[dias_largo - 1].fecha).isoWeekday()
                                for (let index = dia_ultimo_num; index < 7; index++) {
                                    semana.push({
                                        fecha: 0,
                                        turno: 0
                                    })
                                }
                                semanas.push(semana)
                            }
                        }
                        this.calendario.semanas = semanas
                    } else {
                        this.calendario.semanas = 0
                    }
                    this.calendario.mes = respuesta.data.mes
                    this.calendario.feriados = respuesta.data.feriados
                    this.calendario.inicio = respuesta.data.rango.ini
                    this.calendario.termino = respuesta.data.rango.ter
                    this.calendario.servicios = respuesta.data.ser
                    this.calendario.fu = respuesta.data.fu
                }
            }).catch(err => {
                this.overlay = false
                this.ErrorRequest(err)
            })
        },
        GetMes(fecha, operacion) {
            switch (operacion) {
                case 0: //cambiar mes
                    this.GetData(dayjs(fecha).format('YYYYMM'), this.usuario_id)
                    break;
                case 1: //mes siguiente
                    this.GetData(dayjs(fecha).add(1, 'month').format('YYYYMM'), this.usuario_id)
                    break;
                case -1: //mes anterior
                    this.GetData(dayjs(fecha).subtract(1, 'month').format('YYYYMM'), this.usuario_id)
                    break;
            }
            this.BtnMes = dayjs(fecha).format('YYYY-MM')
        },
        GetTurno(fecha) {
            if (fecha) {
                this.turno_fecha = fecha
                this.dialog_turno = true
                this.OpenDialog('turno')
                //window.history.pushState('Usuario_dialog', 'Usuario_dialog', null)
            }
        },
        async GetDetalleServicios() {
            this.resumen = {}
            this.OpenDialog('calendario_resumen')
            let url = `/calendario/resumen/${this.calendario.mes}`
            if (this.usuario_id) url = `/calendario/resumen/${this.calendario.mes}/${this.usuario_id}`
            await this.axios.get(url, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    if (respuesta.data) {
                        this.resumen = Object.freeze(respuesta.data)
                    }
                }
            }).catch(err => {
                this.ErrorRequest(err)
                this.CloseDialog('calendario_resumen')
            })
        },
    },
    mounted() {
        if (this.usuario_id) this.GetData(this.fecha, this.usuario_id)
        else this.GetData(this.fecha, null)
    },
    watch: {
        usuario_id: function (val) {
            this.GetData(dayjs().format('YYYYMM'), val)
        },
        fecha: function (val) {
            if(val) this.GetData(dayjs(val).format('YYYYMM'), this.usuario_id)
        },
        UpdateComponente: function (val) {
            if (val) {
                this.GetData(dayjs().format('YYYYMM'), this.usuario_id)
                this.update.calendario = false
            }
        },
        DialogTurno: function (val) {
            if (!val) this.dialog_turno = false
        }
    }
}
</script>

<style scoped>
.v-data-table>.v-data-table__wrapper>table {
    width: 100%;
    border-spacing: 0;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
    padding: 0px 6px 0px 6px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgb(255 255 255 / 0);
}

.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgb(255 255 255 / 0);
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 0.875rem;
    height: 56px;
}

.celda-calendario {
    padding: 0 !important;
}

.celda-calendario-fuera {
    padding: 0 !important;
}

.v-application--is-ltr .v-list-item__avatar:first-child {
    margin-right: 0px;
}

.celda-hoy {
    background-color: var(--v-primary-base);
    color: white;
}

.celda-data {
    display: contents;
}

.v-date-picker-table {
    position: relative;
    padding: 0 12px;
    height: 146px;
}

.v-date-picker-table .v-date-picker-table--month .theme--light {
    height: 146px;
}

.nombre-turno {
    line-height: .9rem;
}

#calendario>.v-data-table__wrapper {
    border-radius: 8px !important;
}

.v-list-item__avatar {
    justify-content: center;
}

.chip-equipo {
    position: absolute
}

.resumen-disabled {
    opacity: .5;
}

.calendario_divider{
    min-height: 40px;
    max-height: 50px;
}
</style>
